<template>
  <div class="home-more-pc">
    <pc-header></pc-header>
    <div class="main">
      <div class="tab">
        <p>所有活动</p>
        <pc-tab @change="change"></pc-tab>
        <div class="wrap" v-if="index == 0">
          <pc-video
            v-for="(item, i) in nowLiveList"
            :key="i"
            :data="item"
            @refDataList="change(0)"
          ></pc-video>
        </div>
        <div class="wrap" v-else-if="index == 1">
          <pc-video
            v-for="(item, i) in waitLiveList"
            :key="i"
            :data="item"
            @refDataList="change(1)"
          ></pc-video>
        </div>
        <div class="wrap" v-else-if="index == 2">
          <pc-video
            v-for="(item, i) in reviewLiveList"
            :key="i"
            :data="item"
            @refDataList="change(2)"
          ></pc-video>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../../components/pc-header.vue";
import PcFooter from "../../../components/pc-footer.vue";
import PcTab from "../../../components/pc-tab.vue";
import PcVideo from "../../../components/pc-video.vue";
import { liveList } from "../../../api/live";
export default {
  components: {
    PcHeader,
    PcFooter,
    PcTab,
    PcVideo,
  },
  data() {
    return {
      index: 1,
      waitLiveList: [],
      nowLiveList: [],
      reviewLiveList: [],
    };
  },
  created() {
    this.getLiveList(1);
  },
  methods: {
    change(i) {
      this.index = i;
      if (i == 0) {
        this.getLiveList(0);
      } else if (i == 1) {
        this.getLiveList(1);
      } else if (i == 2) {
        this.getLiveList(2);
      }
    },
    // 查询直播数据
    getLiveList(number) {
      let formData = {};
      if (number == 0) {
        formData = {
          liveStatus: "1",
          type: "0",
          rank: "liveTime",
          rankTwo: "creationTime",
        };
      } else if (number == 1) {
        formData = {
          liveStatus: "0",
          type: "0",
          rank: "liveTime",
          rankTwo: "creationTime",
        };
      } else if (number == 2) {
        formData = {
          liveStatus: "2",
          key: "1,0",
          rank: "liveTime",
          rankTwo: "creationTime",
        };
      }
      liveList(formData).then((res) => {
        if (number == 0) {
          this.nowLiveList = res.data;
        } else if (number == 1) {
          this.waitLiveList = res.data;
        } else if (number == 2) {
          this.reviewLiveList = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.home-more-pc {
  height: 100%;
  background: url("../../../assets/pcbg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    width: 1200px;
    margin: 0 auto;
    padding: 30px 0 50px;
    .tab {
      p {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        height: 60px;
        line-height: 60px;
      }
      .wrap {
        padding-top: 30px;
        display: flex;
        flex-wrap: wrap;
        min-height: 480px;
        .pc-video {
          margin-bottom: 35px;
        }
        .pc-video:nth-child(3n-1) {
          margin: 0 15px 35px;
        }
      }
    }
  }
}
</style>
