<template>
  <div class="page-view">
    <home-more-mob v-if="isMobile"></home-more-mob>
    <home-more-pc v-else></home-more-pc>
  </div>
</template>

<script>
import HomeMorePc from "./home-more-pc.vue";
import HomeMoreMob from "./home-more-mob.vue";
export default {
  components: {
    HomeMorePc,
    HomeMoreMob,
  },
  metaInfo: {
    title: "活动列表",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
  created() {
    // console.log(this.isMobile);
  },
};
</script>
